import ListenerMessage from "~/ts/library/listen/ListenerMessage";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import {NativeCrossTabFacade} from "~/ts/library/crossTabCommunication/NativeCrossTabFacade";

const LAST_LISTENER_MESSAGE_TIME = "LastListenerMessageTime";


export default abstract class AbstractListenerAction {

    public abstract getAction(): string;

    public abstract onAction(payload: any, message: ListenerMessage): void;

    public async receiveListenerMessage(payload: any, message: ListenerMessage): Promise<void> {
        //PUSH_TO_DEBUG_CONSOLE("start lock for " + message.time);

        await NativeCrossTabFacade.request('AbstractListenerAction', async () => {
            try {
                //PUSH_TO_DEBUG_CONSOLE("lock successfull " + message.time);
                let allowed = true;

                if (!this.isRunOnMultipleTabsAllowed()) {
                    //PUSH_TO_DEBUG_CONSOLE("start process message " + JSON.stringify(message));
                    if (StorageInstance.isPersistent()) {
                        //PUSH_TO_DEBUG_CONSOLE("isPeristent");

                        let time = StorageInstance.get(LAST_LISTENER_MESSAGE_TIME);
                        //PUSH_TO_DEBUG_CONSOLE("last time is " + time);

                        if (!time || time < message.time) {
                            //PUSH_TO_DEBUG_CONSOLE("allowed");
                            StorageInstance.set(LAST_LISTENER_MESSAGE_TIME, message.time);
                        } else {
                            //PUSH_TO_DEBUG_CONSOLE("disallowed")
                            allowed = false;
                        }
                    }
                }
                //PUSH_TO_DEBUG_CONSOLE("receive " + allowed + " " + message.time);
                if (allowed) {
                    //PUSH_TO_DEBUG_CONSOLE(" process message " + JSON.stringify(message));
                    this.onAction(payload, message);
                }
                //PUSH_TO_DEBUG_CONSOLE("unlock " + message.time);
            } catch (e) {
                console.error(e);
            }
        });
        /*
        let locker = new CrossTabLock('AbstractListenerAction');
        console.log("start lock for", message.time);
        await locker.lock(true);
        console.log("lock successfull", message.time);
        let allowed = true;

        if (!this.isRunOnMultipleTabsAllowed()) {
            if (StorageInstance.isPersistent()) {
                let time = StorageInstance.get(LAST_LISTENER_MESSAGE_TIME);
                if (!time || time < message.time) {
                    StorageInstance.set(LAST_LISTENER_MESSAGE_TIME, message.time);
                } else {
                    allowed = false;
                }
            }
        }
        console.log("receive", allowed, message.time);
        if (allowed) {
            this.onAction(payload, message);
        }
        console.log("before unlock", message.time);
        await locker.unlock();
        console.log("unlock", message.time);
        */
    }

    protected isRunOnMultipleTabsAllowed(): boolean {
        return true;
    }
}