import {OperatorsStore} from "~/chat/ts/store/Operators";
import Dictionary from "~/ts/library/Dictionary";
import {OperatorStatus} from "~/chat/ts/data/OperatorStatus";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class OperatorsOnlineUpdatedListener extends AbstractChatListenerAction {
    public getAction(): string {
        return "operatorsOnlineUpdated";
    }

    public onAction(payload: { data: Dictionary<OperatorStatus> }): void {
        OperatorsStore.setStatusFromDictionary(payload.data);
    }
}