import CrossTabMessage from "./CrossTabMessage";
import LocalStorage from "../../storage/LocalStorage";
import {StorageInstance} from "../../storage/StorageFactory";
import Dictionary from "../../Dictionary";
import Random from "~/ts/library/Random";
import {INSTANCE_ID} from "~/ts/library/InstanceId";
import {Timeouts} from "~/ts/library/delay/Timeouts";
import {removeArrayElement} from "~/ts/library/ToggleArrayElement";
import TrackIsOnTab from "~/ts/library/crossTabCommunication/TrackIsOnTab";


export default class CrossTabSender {
    public static send(message: CrossTabMessage): Promise<any> {
        return new Promise((resolve, reject) => {
            if (StorageInstance instanceof LocalStorage) {

                let callbackId = Random.randomNumber(1, 1000000000);

                CrossTabSender.addCallback(message, resolve, reject);

                let key = "tabTalk_" + callbackId;
                //console.log("send", key, JSON.stringify(message));
                StorageInstance.set(key, message);
                StorageInstance.remove(key);
            }
        });
    }

    private static callbacks: Dictionary<(data: any) => void> = {};

    private static removeCallback(callbackId: number) {
        delete CrossTabSender.callbacks[callbackId];
    }

    private static addCallback(message: CrossTabMessage, resolve: (data: any) => void, reject: () => void) {
        if (message.callbackTimeout) {
            CrossTabSender.callbacks[message.callbackId] = resolve;
            Timeouts.set(() => {
                if (CrossTabSender.callbacks[message.callbackId]) {
                    CrossTabSender.removeCallback(message.callbackId);
                    reject();
                }
            }, message.callbackTimeout);
        }
    }

    private static actions: Dictionary<((payload: any, callback: (response: any) => void) => void)[]> = {};

    public static setMessageCallback(action: string, callback: (payload: any, callback: (response: any) => void) => void) {
        if (!CrossTabSender.actions[action]) {
            CrossTabSender.actions[action] = [];
        }
        if (CrossTabSender.actions[action].indexOf(callback) == -1) {
            CrossTabSender.actions[action].push(callback);
        }
    }

    public static removeMessageCallback(action: string, callback: (payload: any, callback: (response: any) => void) => void) {
        let actions = CrossTabSender.actions[action];
        if (actions) {
            removeArrayElement(actions, callback);
        }
        /*
        if (CrossTabSender.actions[action]) {
            let index = CrossTabSender.actions[action].indexOf(callback);
            if (index > -1) {
                CrossTabSender.actions[action].splice(index, 1);
            }
        }

         */
    }

    public static init() {
        CrossTabSender.initCoreActions();
        CrossTabSender.initLocalStorageListener();
    }

    public static async hasOnTab(): Promise<boolean> {
        try {
            await CrossTabSender.send(new CrossTabMessage("hasIsOnTab", {}, 100));
            return true;
        } catch (e) {
            return false;
        }
    }

    private static initCoreActions() {
        CrossTabSender.setMessageCallback("ping", (payload: any, callback: (response: any) => void) => {
            if (payload.instanceId === INSTANCE_ID) {
                callback({pong: true});
            }
        });

        CrossTabSender.setMessageCallback("hasIsOnTab", (payload: any, callback: (response: any) => void) => {
            if (TrackIsOnTab.isOnTab()) {
                callback({pong: true});
            }
        });

        CrossTabSender.setMessageCallback("callback", (payload: any, callback: (response: any) => void) => {
            if (payload.receiver === INSTANCE_ID) {
                if (CrossTabSender.callbacks[payload.callbackId]) {
                    let resolve = CrossTabSender.callbacks[payload.callbackId];
                    CrossTabSender.removeCallback(payload.callbackId);
                    resolve(payload.responsePayload);
                }
            }
        });
    }

    private static runAction(message: CrossTabMessage, callback: (payload: any) => void) {

        let actions = CrossTabSender.actions[message.action];
        if (actions) {
            for (let i = 0; i < actions.length; i++) {
                actions[i](message.payload, callback);
            }
        }
    }

    private static initLocalStorageListener() {
        LocalStorage.addEventListener((key, e) => {
            if (e.newValue && key.indexOf("tabTalk_") === 0) {
                let message = JSON.parse(e.newValue);
                if (message && message.from !== INSTANCE_ID) {
                    let responseCallback = (responsePayload: any) => {
                        let responseMessage = new CrossTabMessage("callback", {
                            callbackId: message.callbackId,
                            receiver: message.from,
                            responsePayload: responsePayload
                        });
                        CrossTabSender.send(responseMessage);
                    };
                    CrossTabSender.runAction(message, responseCallback);
                }
            }
        });
    }
}