import {ConfigStore} from "~/chat/ts/store/Config";
import {WidgetStore} from "~/chat/ts/store/Widget";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class CloseWidget extends AbstractChatListenerAction {
    public getAction(): string {
        return "closeWidget";
    }

    public onAction(payload: { widgetInstanceId: string }): void {
        if (payload.widgetInstanceId == ConfigStore.instanceId.value) {
            WidgetStore.close();
        }
    }

    protected isRunOnMultipleTabsAllowed(): boolean {
        return true;
    }
}