import {
    FIELD_TYPE_CHECKBOX,
    FIELD_TYPE_DATE,
    FIELD_TYPE_DATETIME,
    FIELD_TYPE_FILE_SHARE,
    FIELD_TYPE_MOBILE,
    FIELD_TYPE_RADIO,
    FIELD_TYPE_SELECT,
    FIELD_TYPE_STARS,
    FIELD_TYPE_TEXT,
    FIELD_TYPE_TEXTAREA
} from "~/cabinet/vue/interface/form/elements/Interfaces";

export const CLIENT_DATA_TYPE_TEXT = FIELD_TYPE_TEXT;//"text";
export const CLIENT_DATA_TYPE_TEXTAREA = FIELD_TYPE_TEXTAREA;//"textarea";
export const CLIENT_DATA_TYPE_SELECT = FIELD_TYPE_SELECT;//"select";
export const CLIENT_DATA_TYPE_CHECKBOX = FIELD_TYPE_CHECKBOX;//"checkbox";
export const CLIENT_DATA_TYPE_RADIO = FIELD_TYPE_RADIO; //"radio";
export const CLIENT_DATA_TYPE_DATE = FIELD_TYPE_DATE;// "date";
export const CLIENT_DATA_TYPE_DATETIME = FIELD_TYPE_DATETIME;// "datetime";
export const CLIENT_DATA_TYPE_FILE = FIELD_TYPE_FILE_SHARE;//"file";
export const CLIENT_DATA_TYPE_PHONE = FIELD_TYPE_MOBILE;
export const CLIENT_DATA_TYPE_EMAIL = "email";
export const CLIENT_DATA_TYPE_EULA = "eulaAgreement";
export const CLIENT_DATA_TYPE_CAPTCHA = "captcha";
export const CLIENT_DATA_TYPE_STARS = FIELD_TYPE_STARS;


export const CLIENT_CAPTCHA_FIELD_NAME = "g-recaptcha-response";

export const UTM_KEYS = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "utm_referrer"];
