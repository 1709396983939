import {ConfigStore} from "~/chat/ts/store/Config";
import showTab from "~/chat/ts/service/api/methods/ShowTab";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class OpenWidgetTabListener extends AbstractChatListenerAction {
    public getAction(): string {
        return "openWidgetTab";
    }

    public onAction(payload: {
        tabId: string,
        widgetInstanceId?: string,
        additionalParams: any
    }): void {
        let instanceId = payload.widgetInstanceId;
        if (!instanceId || instanceId == ConfigStore.instanceId.value) {
            showTab({id: payload.tabId, ...payload.additionalParams});
        }
    }

    protected isRunOnMultipleTabsAllowed(): boolean {
        return true;
    }
}