import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import Filters from "~/chat/ts/service/autoActions/Filters";
import {ACTION_FILTER_TIME_FROM_LAST_MESSAGE_FROM_OPERATOR} from "~/chat/ts/data/AutoActions";
import {OperatorsStore} from "~/chat/ts/store/Operators";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class OperatorTypingListener extends AbstractChatListenerAction {
    public getAction(): string {
        return "typing";
    }

    public onAction(payload: { operator: string, name?: string, ttl?: number, isBot?: boolean }): void {
        let operator = OperatorsStore.getOperatorByLoginHash(payload.operator);
        MessageStorage.setOperatorTyping({
            operatorId: operator?.id,
            operatorName: payload.name,
            timeout: payload.ttl ? payload.ttl * 1000 : null,
            isBot: payload.isBot
        });
        Filters.set(ACTION_FILTER_TIME_FROM_LAST_MESSAGE_FROM_OPERATOR);
    }
}
/*
{"id":1,"channel":"14755221258159fdb93bdc95487328cc4cc83208","text":{"action":"editMessage","id":184744097,"text":"в пердакшен!"},"time":"1561049769050","tag":0}
 */