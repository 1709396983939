import {ConfigStore} from "~/chat/ts/store/Config";
import Dictionary from "~/ts/library/Dictionary";
import Url from "~/ts/library/Url";
import OnlineChatLocalization from "~/chat/ts/OnlineChatLocalization";
import {WidgetStore} from "~/chat/ts/store/Widget";
import Translate, {__} from "~/ts/library/Translate";
import ArrayHelper from "~/ts/library/ArrayHelper";
import {ClientStore} from "~/chat/ts/store/Client";
import ISiteParams from "~/chat/ts/data/ISiteParams";
import {ISetup} from "~/chat/ts/data/ISetup";
import {CLIENT_CAPTCHA_FIELD_NAME, CLIENT_DATA_TYPE_EULA} from "~/chat/ts/data/IClient";
import AnalyticCounters from "~/chat/ts/service/AnalyticCounters";
import punycode from "~/ts/library/Punycode";


export default class InitConfiguration {
    public static async init(config: Dictionary<ISiteParams>, setup: ISetup): Promise<boolean> {
        ConfigStore.SET_SETUP(setup);
        await ClientStore.initClientId();
        ConfigStore.initConfiguration(config);
        AnalyticCounters.init();

        let result = this.isPassedLoadLimits();
        if (result) {
            await this.initLocalization(ConfigStore.language.value);
            ConfigStore.SET_FIELD_DESCR({descr: __("NEED_EULA"), name: CLIENT_DATA_TYPE_EULA});
            ConfigStore.SET_FIELD_DESCR({descr: __("NEED_CAPTCHA"), name: CLIENT_CAPTCHA_FIELD_NAME});
        }

        WidgetStore.setInitialSize();

        return result;
    };

    private static isPassedLoadLimits(currentUrl?: string): boolean {
        let allowed = true;
        let siteParams = ConfigStore.siteParams.value;
        let loadLimits = siteParams.params.siteChatOptions.loadLimits;

        let type = loadLimits.type;
        let urlList = loadLimits.url;
        if (urlList && urlList.length > 0) {
            if (type == "excludeRegexp" || type == "someRegexp") {
                if (type == "someRegexp") {
                    allowed = false;
                }
                for (let regexp of urlList) {
                    try {
                        var compiledRegexp: RegExp;
                        eval(`compiledRegexp = ${regexp};`);
                        if (compiledRegexp) {
                            let result = window.location.href.match(compiledRegexp);
                            if (result) {
                                allowed = type == "someRegexp";
                                break;
                            }
                        } else {
                            console.error("Не удалось получить regexp", regexp);
                        }
                    } catch (e) {
                        console.error("Не удалось скомпилировать regexp", regexp, e);
                    }
                }
            } else {
                urlList = loadLimits.url ? ArrayHelper.unique(loadLimits.url.reduce((array: string[], item: string) => {
                    item = item.toLowerCase();
                    array.push(item, encodeURI(item).toLowerCase());
                    return array;
                }, [])) : [];

                if (loadLimits.type !== "none" && urlList.length) {
                    allowed = (loadLimits.type === "exclude");
                    if (currentUrl == null) {
                        currentUrl = window.location.href;
                    }

                    let parseURL = new Url(currentUrl);
                    let host = parseURL.hostname;
                    let domain = punycode.toAscii(siteParams.domain);
                    //let punycodeDomain = punycode.toAscii("впечатляй.рф");
                    if (host !== domain && host.indexOf("." + domain) > -1) {
                        let domainLength = domain.length;
                        if (host.substr(host.length - domainLength) === domain) {
                            host = host.substr(0, host.length - domainLength - 1);
                        }
                        let subdomains = host.split(".").filter(item => item !== "www");
                        if (subdomains.length) {
                            parseURL.pathname = "/" + subdomains.join("/") + parseURL.pathname;
                        }
                    }
                    currentUrl = parseURL.pathname + parseURL.search;
                    if (parseURL.hash && parseURL.hash.length) {
                        currentUrl += parseURL.hash;
                    }
                    if (currentUrl.indexOf('/') != 0) {
                        currentUrl = "/" + currentUrl;
                    }
                    if (currentUrl.length > 1 && currentUrl.slice(-1) == '/') {
                        currentUrl = currentUrl.substr(0, currentUrl.length - 1);
                    }
                    currentUrl = currentUrl.toLowerCase();


                    for (let url of urlList) {
                        let dividedByStar = url.split("*");
                        if (
                            url == currentUrl
                            ||
                            url + "/" == currentUrl
                            ||
                            url == currentUrl.substr(0, url.length - 1) + "*"
                            ||
                            url.split('/*')[0] == currentUrl
                            ||
                            (
                                dividedByStar.length == 3
                                &&
                                currentUrl.includes(dividedByStar[1])
                            )
                            ||
                            (
                                url.startsWith("*")
                                &&
                                currentUrl.endsWith(url.substring(1))
                            )
                        ) {
                            allowed = !allowed;
                            break;
                        }
                    }
                }
            }
        }
        return allowed;
    }

    private static initLocalization(language: string): Promise<void> {
        return new Promise<void>((resolve) => {
            if (language == "ru") {
                InitConfiguration.applyLocalization(OnlineChatLocalization);
                resolve();
            } else {
                import("@/" + language + "/locale.bundle.js").then((loader: any) => {
                    loader.default(function (module: any) {
                        InitConfiguration.applyLocalization(module.default);
                        (window as any).module = module;
                        resolve();
                    }, function () {
                    });
                }).catch(() => {
                });

                /*
                require("@/" + language + "/locale.bundle.js")(function (module: any) {
                    InitConfiguration.applyLocalization(module.default/*, module.momentLocale* /);
                    resolve();
                });
                */
            }
        });
    }

    private static applyLocalization(strings: Dictionary<string>/*, locale?: IMomentLocale*/) {
        let overrides = ConfigStore.siteParams.value.params.userLanguageOverrideStrings;
        if (overrides) {
            for (let key in OnlineChatLocalization) {
                if (OnlineChatLocalization.hasOwnProperty(key)) {
                    let ruValue = OnlineChatLocalization[key];
                    if (typeof overrides[ruValue] == "string") {
                        strings[key] = overrides[ruValue];
                    }
                }
            }
        }
        /*
        if (locale) {
            moment.defineLocale(locale.languageId, locale.params);
        }

         */
        Translate.setStrings(strings);
    }

}