import {StorageInstance} from "../storage/StorageFactory";
import LocalStorage from "../storage/LocalStorage";
import CrossTabSender from "./talk/CrossTabSender";
import CrossTabMessage from "./talk/CrossTabMessage";
import Delay from "~/ts/library/Delay";
import {INSTANCE_ID} from "~/ts/library/InstanceId";
import TrackIsOnTab from "~/ts/library/crossTabCommunication/TrackIsOnTab";

let instanceId = INSTANCE_ID;

class CrossTabLock {
    private type: string;
    private timeout: number;
    private onlyOnCurrentTab: boolean = false;

    constructor(type: string, timeout: number = 0) {
        this.type = type;
        this.timeout = timeout;
    }

    setOnlyOnCurrentTab(value: boolean) {
        this.onlyOnCurrentTab = value;
        return this;
    }


    public async lock(waitUntilSuccessfullyLock: boolean = false, forUnlock: boolean = false): Promise<boolean> {
        let $this = this;
        let lockResult = true;
        let onlyOnCurrentTab = this.onlyOnCurrentTab && !forUnlock;
        if (onlyOnCurrentTab && !TrackIsOnTab.isOnTab()) {
            lockResult = false;
        } else if (StorageInstance instanceof LocalStorage) {
            let result = $this.getValue();

            if (!result || result === $this.skipInstanceIdOnce || result === instanceId) {
                $this.skipInstanceIdOnce = null;
                $this.setValue();
                await Delay.make(CrossTabLock.checkTimeout);
                lockResult = $this.check();
            } else {
                try {
                    await CrossTabSender.send(new CrossTabMessage("ping", {instanceId: result}, 100));
                    lockResult = false;
                } catch (e) {
                    $this.skipInstanceIdOnce = result;
                    return await $this.lock(waitUntilSuccessfullyLock, forUnlock);
                }
            }
        }

        if (!lockResult && waitUntilSuccessfullyLock) {
            return await this.lock(waitUntilSuccessfullyLock, forUnlock);
        }

        return lockResult;
    }

    public check(): boolean {
        return this.getValue() === instanceId;
    }

    public async unlock(): Promise<void> {
        await this.lock(/*true*/true, true);
        this.unsetValue();
    }


    private static checkTimeout: number = 50;
    private skipInstanceIdOnce: string;

    private getKey() {
        return "locks::" + this.type;
    }

    private getValue(): string | null {
        let result = StorageInstance.get(this.getKey(), true);
        if (result) {
            if (result.timeout) {
                if ((new Date()).getTime() > result.timeout + result.timestamp) {
                    result.id = null;
                }
            }
            result = result.id;
        }
        return result;
    }

    private setValue() {
        StorageInstance.set(this.getKey(), {
            id: instanceId,
            timeout: this.timeout,
            timestamp: (new Date()).getTime()
        });
    }

    private unsetValue() {
        StorageInstance.remove(this.getKey());
    }


}

export default CrossTabLock;