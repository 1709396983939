import AbstractListenChannel from "../AbstractListenChannel";
import ListenerMessage from "../ListenerMessage";
import CacheChannel from "./CacheChannel";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";

abstract class RealChannel extends AbstractListenChannel {
    protected url: string;

    constructor(uid: string, url: string, currentCometTimestamp?: number) {
        super(uid, currentCometTimestamp);
        this.url = url;
    }

    protected receiveMessage(message: ListenerMessage): void {
        if (message.serverTime) {
            StorageInstance.set(AbstractListenChannel.SERVER_UTC_DELTA, Date.now() - message.serverTime);
        }
        super.receiveMessage(message);
        CacheChannel.writeMessageToCache(this.uid, message);
    }
}

export default RealChannel;