import {OperatorsStore} from "~/chat/ts/store/Operators";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class SelectOperatorListener extends AbstractChatListenerAction {
    public getAction(): string {
        return "selectOperator";
    }

    public onAction(payload: {
        operatorId: string
    }): void {
        let operator = OperatorsStore.getOperatorById(payload.operatorId);
        if (operator) {
            OperatorsStore.setSelectedOperator(operator);
        }
    }
}