import Filters from "~/chat/ts/service/autoActions/Filters";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class SetActionFilterValue extends AbstractChatListenerAction {
    public getAction(): string {
        return "setActionFilterValue";
    }

    public onAction(payload: { name: string, value: any }): void {
        Filters.set(payload.name, payload.value);
    }
}