import MessageStorage from "~/chat/ts/service/messages/MessageStorage";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class MessageRead extends AbstractChatListenerAction {
    public getAction(): string {
        return "messageRead";
    }

    public onAction(payload: { id: number }): void {
        MessageStorage.setMessageReaded(payload.id, false);
    }
}