import {StorageInstance} from "~/ts/library/storage/StorageFactory";
import ChatApiRequest from "~/chat/ts/service/request/ChatApiRequest";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {ConfigStore} from "~/chat/ts/store/Config";
import {ChatTabStore} from "~/chat/ts/store/ChatTab";

export default async function setClientInfo(payload: any, callback?: (error?: Error) => void) {
    if (ConfigStore.siteParams.value.params.api.disableSetClientInfoWithoutDialog) {
        if (!ChatTabStore.isDialogRunning.value) {
            return;
        }
    }
    let valuesWithPath = ObjectHelper.getAllValuesWithPath({r: payload}, true);
    let changed = false;
    for (let item of valuesWithPath) {
        let storeKey = "setClientInfo_" + JSON.stringify(item.path);
        let cache = StorageInstance.getex(storeKey);
        var cacheValue = JSON.stringify(item.value);
        if (cache != cacheValue) {
            StorageInstance.setex(storeKey, cacheValue, 3600000 * 3);
            changed = true;
        }
    }

    let error = null;

    if (changed) {
        /*let result = */
        let result = await ChatApiRequest.make({
            method: "setClientInfo",
            p: payload
        });
        if (!result.isSuccess()) {
            error = new Error(result.getDescr());
        }
        /*
        if (result.isSuccess()) {
            StorageInstance.setex(storeKey, cacheValue, 3600000);
        }

         */
    }
    if (callback) {
        callback(error);
    }
}