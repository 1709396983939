import CrossTabLock from "~/ts/library/crossTabCommunication/CrossTabLock";
import {StorageInstance} from "~/ts/library/storage/StorageFactory";

export class NativeCrossTabFacade {
    public static async request(id: string, callback: () => Promise<void>, options?: LockOptions): Promise<any> {

        if (window.navigator.locks) {
            //PUSH_TO_DEBUG_CONSOLE("NATIVE LOCK START");
            id = `${StorageInstance.getPrefix()}_${id}`;

            const state = await navigator.locks.query();
            for (const lock of state.held) {
                //PUSH_TO_DEBUG_CONSOLE(`held lock: name ${lock.name}, mode ${lock.mode}`);
            }
            for (const request of state.pending) {
                //PUSH_TO_DEBUG_CONSOLE(`requested lock: name ${request.name}, mode ${request.mode}`);
            }

            if (options) {
                await window.navigator.locks.request(id, options, callback)
            } else {
                await window.navigator.locks.request(id, callback);
            }
            //PUSH_TO_DEBUG_CONSOLE("NATIVE LOCK DONE");
        } else {
            //PUSH_TO_DEBUG_CONSOLE("LOCALSTORAGE LOCK START");
            let locker = new CrossTabLock(id);
            let waitUntilLock = options?.ifAvailable !== true;
            if (await locker.lock(waitUntilLock)) {
                //PUSH_TO_DEBUG_CONSOLE("LOCALSTORAGE SUCCESS");
                await callback();
                await locker.unlock();
                //PUSH_TO_DEBUG_CONSOLE("LOCALSTORAGE UNLOCK");
            }
        }
    }
}