import {ChatTabStore, WidgetDataInterface} from "~/chat/ts/store/ChatTab";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class WidgetDataListener extends AbstractChatListenerAction {
    public getAction(): string {
        return "widgetData";
    }

    public onAction(p: WidgetDataInterface): void {
        ChatTabStore.setWidetData(p);
    }
}