import {ConfigStore} from "~/chat/ts/store/Config";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class SetAttachedOperatorsListener extends AbstractChatListenerAction {
    public getAction(): string {
        return "setAttachedOperators";
    }

    public onAction(payload: {
        operatorIds: string[],
        vacationReplacementIds: string[],
        onlyIfOnline: boolean
    }): void {
        ConfigStore.setAttachedOperators(payload);
    }
}