import {ConfigStore} from "~/chat/ts/store/Config";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class RedirectListener extends AbstractChatListenerAction {
    public getAction(): string {
        return "redirect";
    }

    public onAction(payload: { url: string, widgetInstanceId: string }): void {
        if (payload.widgetInstanceId == ConfigStore.instanceId.value) {
            window.location.href = payload.url;
        }
    }

    protected isRunOnMultipleTabsAllowed(): boolean {
        return true;
    }
}