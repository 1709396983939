import {ClientStore} from "~/chat/ts/store/Client";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class SetBanListener extends AbstractChatListenerAction {
    public getAction(): string {
        return "setBan";
    }

    public onAction(payload: { ban: 1 | 0 }): void {
        ClientStore.setBan(!!payload.ban);
    }
}