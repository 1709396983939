import {ChatEventManagerInstance} from "~/chat/ts/ChatEventManager";
import AbstractChatListenerAction from "~/chat/ts/service/listen/AbstractChatListenerAction";

export default class SnippetListener extends AbstractChatListenerAction {
    public getAction(): string {
        return "snippet";
    }

    public onAction(payload: CometOperatorApplication.SnippetCometMessageOpenApiModel): void {
        ChatEventManagerInstance.emit(payload.id, payload)
    }

    protected isRunOnMultipleTabsAllowed(): boolean {
        return true;
    }
}


/*
{"id":1,"channel":"14755221258159fdb93bdc95487328cc4cc83208","text":{"action":"editMessage","id":184744097,"text":"в пердакшен!"},"time":"1561049769050","tag":0}
 */