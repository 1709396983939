import GetRequest from "../../xhr/GetRequest";
import ListenerMessage from "../ListenerMessage";
import RealChannel from "./RealChannel";

class LongPollingChannel extends RealChannel {

    get typeId(): string {
        return "longPolling";
    }

    async startListen(): Promise<void> {
        let url = this.url;
        let random = (new Date()).getTime().toString();
        let time = this.lastMessageTimestamp.toString();

        url += ((url.indexOf("?") === -1) ? "?" : "&") + `time=${time}&r=${random}&json=1`;

        try {
            let result = await (new GetRequest(url))
                .setTimeout(30000)
                .send();

            let data = result.getRawResponse();
            let xhr = result.getXhr();
            let previousTime: any = xhr.getResponseHeader("X-Previous-Time");
            let lastModified = parseInt(xhr.getResponseHeader("Last-Modified"));

            previousTime = previousTime ? parseInt(previousTime) : null;
            if (data != null && data.length) {
                try {
                    data = JSON.parse(data);
                } catch (e) {

                }
                if (Array.isArray(data)) {
                    if (data.length > 0) {
                        let serverTime: any = xhr.getResponseHeader("X-Server-Time");
                        if (lastModified) {
                            this.setLastMessageTimeStamp(this.uid, lastModified, data, previousTime);
                        }
                        for (let message of data) {
                            this.receiveMessage(
                                new ListenerMessage(
                                    message.text,
                                    message.time ? parseInt(message.time) : 0,
                                    message.previousStoredTime,
                                    serverTime ? parseInt(serverTime) : null
                                )
                            );
                        }
                    }
                }
            } else {
                this.checkPreviousMessageTimeStamp(this.uid, previousTime, lastModified, null);
            }
            this.close(false);
        } catch (e) {
            this.close(true);
            throw e;
        }

    }

}

export default LongPollingChannel;